import React from "react"
import {changeLocale, IntlContextConsumer} from "gatsby-plugin-react-intl"

const languageName = {
    ru: "Russian",
    en: "English",
}

const Language = () => {
    return (
        <div>
            <IntlContextConsumer>
                {({languages, language: currentLocale}) =>
                    languages.map(language => (
                        <a
                            href={language}
                            key={language}
                            onClick={() => changeLocale(language)}
                            style={{
                                color: currentLocale === language ? `white` : `rgb(170, 172, 173)`,
                                backgroundColor: currentLocale === language ? `#db4446` : ``,
                                marginLeft: -11,
                                margin: 10,
                                cursor: `pointer`,
                                opacity: 1
                            }}
                        >
                            {languageName[language]}
                        </a>
                    ))
                }
            </IntlContextConsumer>
        </div>
    )
}

export default Language
