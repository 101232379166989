import React from "react"
import PropTypes from "prop-types"
import {injectIntl, useIntl} from "react-intl"

import NpmNavbar from "./navBar"
import NpmFooter from "./footer"
import Seo from "./seo"

import "bootstrap/dist/css/bootstrap.min.css"
import "katex/dist/katex.min.css"
import "./styles/npm-site.scss"
import {Helmet} from "react-helmet";

const NpmLayout = ({children}) => {
    const intl = useIntl();
    const lang = intl.locale;
    return (
        <>
            <Helmet>
                <html className="npm-site" lang={lang}/>
            </Helmet>
            <Seo title={intl.formatMessage({id: "title"})}/>

            <NpmNavbar/>
            <div className="container">
                <main>{children}</main>
            </div>
            <NpmFooter/>
        </>
    )
}

NpmLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default injectIntl(NpmLayout)
