import React from "react"
import "./styles/footer.scss"

const NpmFooter = () => (
    <footer className="blockquote-footer bg-dark">
        <div className="container">
            <p>
                © 2024 mipt-npm lab | Built with
                {` `}
                <a href="https://www.gatsbyjs.org">Gatsby framework</a>
                {` `}
                and
                {` `}
                <a href="https://getbootstrap.com/">Bootstrap styles</a>
            </p>
        </div>
    </footer>
)

export default NpmFooter