import React from "react"
import {Link} from "gatsby"
import {globalHistory as history} from '@reach/router'

import {FormattedMessage, injectIntl, useIntl} from "react-intl"
import {Container, Nav, Navbar} from "react-bootstrap"
import Language from "./language"

import "./styles/navbar.scss"
import logo from "../images/index/logo_white.png"

const NpmNavbar = () => {
    const intl = useIntl();
    const {location} = history;
    let activeBrand = location.pathname === "/ru/" || location.pathname === "/en/" ? 'activeBrand' : "link-no-style";

    let projectsLinkStyle = location.pathname.startsWith(`/${intl.locale}/projects`) ? "nav-item active" : "nav-item"

    return (
        <>
            <Navbar bg="dark" expand="lg" id="site-navbar" fixed="top" variant="dark">
                <Container>
                    <Navbar.Brand>
                        <Link to={"/" + intl.locale + "/"} className={activeBrand}>
                            <img src={logo} height="50px" alt="npm logo" id="logo" style={{marginBottom: 0}}/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="site-navbar-collapse"/>
                    <Navbar.Collapse id="site-navbar-collapse">
                        <Nav className="mr-auto" activeKey={location.pathname}>
                            <Link to={`/${intl.locale}/news`} className="nav-item" activeClassName="active" partiallyActive={true}>
                                <Nav.Link as="span" eventKey="news"><FormattedMessage id="header.news"/></Nav.Link>
                            </Link>
                            <Link to={`/${intl.locale}/about`} className="nav-item" activeClassName="active"
                                  partiallyActive={true}>
                                {/* about */}
                                <Nav.Link as="span" eventKey="about"><FormattedMessage
                                    id="header.group"/></Nav.Link>
                            </Link>
                            <Link to={`/${intl.locale}/projects/physics`} className={projectsLinkStyle}>
                                {/* projects */}
                                <Nav.Link as="span" eventKey="projects">
                                    <FormattedMessage id="header.projects"/>
                                </Nav.Link>
                            </Link>
                            <Link to={`/${intl.locale}/partners`} className="nav-item" activeClassName="active"
                                  partiallyActive={true}>
                                {/* partners */}
                                <Nav.Link as="span" eventKey="partners"><FormattedMessage
                                    id="header.partners"/></Nav.Link>
                            </Link>
                            <Link to={`/${intl.locale}/consulting`} className="nav-item" activeClassName="active"
                                  partiallyActive={true}>
                                <Nav.Link as="span" eventKey="consulting">
                                    <FormattedMessage id="header.consulting"/>
                                </Nav.Link>
                            </Link>
                        </Nav>
                        <Nav.Link eventKey="language" className="nav-item language">
                            <Language/>
                        </Nav.Link>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default injectIntl(NpmNavbar)
